.footer {
	background-color: #000000;
	padding: 2rem 0;
}

.social h5, .footer-header, .copyright {
	color: #fff;
}

.social p a, .footer-sub-link, .disabled-link, .disabled-link:hover {
	color: #707070;
}

.footer-menu {
	letter-spacing: .3px;
}

.social p a, .footer-sub-link, .footer-link {
	transition: all .3s;
}

.social h5, .footer-header {
	font-family: 'Montserrat', sans-serif;
}

.social h5, .footer-header {
	font-size: 1.1rem;
}

.facebook a:hover {
	color: #4267B2;
}

.x a:hover {
	/* color: #1DA1F2; */
  color: #0465b0;
}

.instagram a:hover {
	color: #C13584;
}

.linkedin a:hover {
	color: #006192;
}

.bluesky a:hover {
  color: #4f9bd9;
}

.disabled-link {
	pointer-events: none;
}

.footer-sub-link {
	margin-bottom: .5rem;
  font-size: .9rem;
}

.copyright {
	font-size: .8rem;
}

.footer-sub-link:hover, .footer-link:hover {
	color: #efa900 !important;
}

.copyright {
	margin-bottom: 0;
}
