.about-home {
	background-color: #195a7c;
	color: #ffffff;
	padding: 4rem 0 3rem 0;
}

.about-home h2 {
	font-size: 2.2rem;
}

.about-home h2, .about-home h5 {
	font-family: 'Montserrat', sans-serif;
}

.about-home p a {
	transition: all .3s;
}

.about-home p {
	letter-spacing: .3px;
	font-weight: 400;
}

.about-home i {
	margin-right: .5rem;
}

.about-home a {
	color: inherit;
}

.about-home p a:hover {
	color: #efa900 !important;
}
