.catalog, .catalog-search {
  padding-top: 2rem;
  padding-bottom: 3rem;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.product-page {
  padding-top: 8rem;
}

.catalog h4, .product-page h2, .product-features {
  font-family: 'Montserrat', sans-serif;
}

.product-page h2, .search-input {
  margin-bottom: 1rem;
}

.catalog-bg {
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(25,90,124,1) 69%, rgba(25,90,124,1) 100%);
}

.catalog p, .search-input {
  color: #707070;
  font-weight: 300;
}

.catalog p, .catalog h4, .featured-product-title, .back-btn a {
  letter-spacing: .3px;
}

.catalog p a {
	text-decoration: none;
}

.catalog-link, .product-list li a, .back-btn a:hover {
  color: #efa900;
}

.catalog-link, .product-list li a, .products-link, .back-btn a, .demo-btn, #dropdown-autoclose-true {
  transition: all .3s ease;
}

.catalog-link:hover, .featured-product-title, .product-list li a:hover, .back-btn a {
	color: #195a7c;
}

.catalog h4, .product-features {
  margin-bottom: 1.5rem;
}

.featured-product-title {
  font-size: 1.35rem;
}

.featured-product-title, .search-input, .demo-btn, #dropdown-autoclose-true, .dropdown-menu {
  font-family: 'Rubik', sans-serif;
}

.search-input {
  border: 1px solid #195a7c;
  border-radius: 6px;
  padding: .2rem .4rem;
  width: 100%;
  font-size: .9rem;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.05), 0 1px 10px 5px rgba(0, 0, 0, 0.05), 0 4px 2px -5px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.05), 0 1px 10px 5px rgba(0, 0, 0, 0.05), 0 4px 2px -5px rgba(0, 0, 0, 0.05);
}

.search-input:focus {
  border: 1px solid #efa900;
  outline: none;
}

.product-list li a {
  font-size: 1rem;
}

.product-list li {
  margin-bottom: .5rem;
}

.products-link {
  padding: .4rem 1rem;
  font-size: 1.05rem;
  border-radius: 1.18rem;
  -webkit-text-shadow: 0 0 5px rgba(0, 0, 0, .2);
  text-shadow: 0 0 5px rgba(0, 0, 0, .2);
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0 4px 7px 0 rgba(0, 0, 0, .2);
  letter-spacing: .5px;
}

.products-link, .demo-btn, #dropdown-autoclose-true {
  background-color: #efa900 !important;
  border: none !important;
}

.products-link, .products-link:hover, .demo-btn, #dropdown-autoclose-true {
  color: #fff !important;
}

.products-link, .product-specs strong, .product-note {
  font-weight: 500;
}

.products-link:hover {
  cursor: pointer;
  opacity: .8;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, .3);
  -webkit-box-shadow: 0 2px 8px 1px rgba(0, 0, 0, .3);
}

.demo-btn:hover, #dropdown-autoclose-true:hover {
  background-color: #195a7c !important;
}

.back-btn i {
  font-size: 12px;
}

.back-btn {
  font-size: 14px;
}

.demo-btn, #dropdown-autoclose-true {
  font-weight: 600;
  border-radius: 8px !important;
  letter-spacing: .3px;
  padding: 5px 10px;
  box-shadow: 0 8px 17px 2px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
  -webkit-box-shadow: 0 8px 17px 2px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
}

.demo-btn, .product-not, #dropdown-autoclose-true, .dropdown-item {
  font-size: .8rem;
}

.product-specs {
  margin-bottom: .5rem;
}

.product-note {
  margin-top: 1rem;
}

.product-features {
  color: #195a7c;
}

.product-features u {
  -webkit-text-decoration-color: #efa900;
          text-decoration-color: #efa900;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
