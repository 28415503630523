html, body {
  overflow-x: hidden;
  width: 100%;
  text-align: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:hover {
  text-decoration: none !important;
}

a:active, a:focus, button:focus, button:active, [role="button"]:focus, [role="button"]:active, .btn:focus, .btn:active, #dropdown-autoclose-true:focus, #dropdown-autoclose-true:active {
  outline: none !important;
  border: none !important;
  box-shadow: none;
}

p, ul, nav {
  font-family: 'Rubik', sans-serif;
}

ul {
  padding-left: 0 !important;
}

/* Handles the height of every page. Keeps footer fixed to bottom */
.body-content {
  min-height: calc(100vh - 330px);
}

.wrapper {
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(25,90,124,1) 69%, rgba(25,90,124,1) 100%);
  height: 15rem;
}

.wrapper h1 {
  font-family: 'Montserrat', sans-serif;
  color: #efa900;
  text-align: center;
  padding-top: 7.5rem;
}

/* page transitions */
.fadeIn {
  animation: 0.2s fadeIn forwards;
}

.fadeOut {
  animation: 0.2s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(0px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0px, 0);
    opacity: 0;
  }
}

/* Responsive CSS */
@media (max-width: 991px) {
  .main {
    background-image: linear-gradient(90deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.2) 100%), url(https://www.bmtmicro.com/images/bmt-micro-header-background-1000px-min.jpg);
  }
  .header-section {
    padding: 8rem 15px;
  }
  .header-hero, .product-overview p {
    margin-bottom: 2.5rem;
  }
  .about-home h2 {
    margin-bottom: 1.2rem;
  }
  .about-home p:first-child {
    margin-bottom: 2rem;
  }
  .product-overview {
    background-image: linear-gradient(176deg, #195a7c 20%, #fff calc(20% + 2px));
  }
  .product-overview h1 {
    font-size: 2.5rem;
  }
  .product-overview-item {
    margin-bottom: 6.5rem;
  }
  .nav-button {
    text-align: center;
    margin-left: 25%;
    margin-right: 25%;
  }

}
@media (min-width: 992px) {
  .main {
    background-image: linear-gradient(90deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.2) 100%), url(https://www.bmtmicro.com/images/bmt-micro-header-background-min.jpg);
  }
  .header-section {
    padding: 15rem 0;
  }
  .about-home h2 {
    margin-bottom: 2rem;
  }
  .about-home p:first-child {
    margin-bottom: 1rem;
  }
  .product-overview {
    background-image: linear-gradient(176deg, #195a7c 23%, #fff calc(23% + 2px));
  }
  .product-overview h1 {
    font-size: 2.8rem;
  }
  .product-overview-item {
    margin-bottom: 8rem;
  }

}
@media (max-width: 767px) {
  .main {
    background-image: linear-gradient(90deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.2) 100%), url(https://www.bmtmicro.com/images/bmt-micro-header-background-775px-min.jpg);
  }
  .header-hero {
    margin-bottom: 0;
  }
  .header-screenshots {
    display: none;
  }
  .catalog-bg .wrapper h1 {
    font-size: 2rem;
  }
  .product-overview {
    background-image: linear-gradient(176deg, #195a7c 20%, #fff calc(20% + 2px));
  }
  .product-overview h1 {
    font-size: 2.3rem;
  }
  .product-overview h5 {
    line-height: 1.5;
  }
  .card {
    margin-bottom: 15px;
  }
  .pricing-background {
    background-image: url(https://www.bmtmicro.com/images/bmt-micro-pricing-background-800px-min.jpg);
  }
}
@media (min-width: 768px) {
  .pricing-background {
    background-image: url(https://www.bmtmicro.com/images/bmt-micro-pricing-background-min.jpg);
  }
  .product-overview h5 {
    line-height: 2;
  }
}
@media (max-width: 657px) {
  .banner h4 {
    font-size: 1.2rem;
  }
}
@media (min-width: 658px) {
  .banner h4 {
    font-size: 1.4rem;
  }
}
@media (max-width: 575px) {
  .wrapper {
    height: 12rem;
  }
  .wrapper h1 {
    font-size: 2.2rem;
    padding-top: 6.5rem;
  }
  .news-title {
    padding-left: 15px;
  }
  .media-contact {
    margin-left: 10px;
  }
  .card-row {
    margin-bottom: 1.5rem;
  }
  .tier-text {
    padding: .3rem 2rem;
  }
  .social {
    margin-bottom: .5rem;
  }
  .catalog-bg .wrapper h1 {
    font-size: 1.8rem;
  }
  .info-section, .top-img {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 576px) {
  .news-title {
    padding-left: 0px !important;
  }
  .card-row, .info-section {
    margin-bottom: 3rem;
  }
  .tier-text {
    padding: .3rem 2.5rem;
  }
}
@media (max-width: 552px) {
  .header-section h1 {
    font-size: 2.5rem;
  }
  .header-section p {
    font-size: 1rem;
  }
}
@media (min-width: 553px) {
  .header-section h1 {
    font-size: 2.9rem;
  }
  .header-section p {
    font-size: 1.2rem;
  }
}
@media (max-width: 445px) {
  .tier-text {
    padding: .3rem 1rem;
  }
  .pricing h5 {
    text-align: left;
    font-size: 1.1em;
  }
}
@media (min-width: 446px) {
  .pricing h5 {
    text-align: center;
    font-size: 1.25rem;
  }
}
@media (max-width: 445px) {
  .product-overview {
    background-image: linear-gradient(176deg, #195a7c 22%, #fff calc(22% + 2px));
  }
}
@media (min-width: 430px) {
  .overview-img-col img {
    width: 400px;
  }
}
@media (max-width: 429px) {
  .overview-img-col img {
    width: 330px;
  }
}
@media (max-width: 376px) {
  .affiliates-btn {
    font-size: .9rem;
    padding: .6rem 1.7rem;
  }
}
@media (min-width: 377px) {
  .affiliates-btn {
    font-size: 1.2rem;
    padding: .9rem 2rem;
  }
}
