.navbar {
  background-color: rgba(255, 255, 255, .95);
}

nav {
  box-shadow: 0px 1px 7px 1px rgb(0 0 0 / 20%);
  -webkit-box-shadow: 0px 1px 7px 1px rgb(0 0 0 / 20%);
}

.navbar-brand:hover {
  cursor: pointer;
}

.nav-link, .dropdown-item {
  transition: all .3s;
  letter-spacing: .3px;
}

.nav-link {
  padding: .5rem 1rem !important;
}

.main-link, .dropdown-toggle {
  color: #195a7c !important;
}

.nav-link:hover {
  color: rgba(25, 90, 124, .6) !important;
  text-decoration: none;
}

.active, .dropdown-style .active {
  color: #efa900 !important;
}

.dropdown-menu {
  border: 1px solid rgba(0,0,0,.15);
  box-shadow: 0px 3px 10px 1px rgb(0 0 0 / 30%);
  -webkit-box-shadow: 0px 3px 10px 1px rgb(0 0 0 / 30%);
  border-radius: .25rem;
}

.dropdown-style {
  padding: .25rem 1rem;
  font-size: .9rem;
}

.dropdown-style:hover {
  color: #000 !important;
  background-color: #f8f9fa;
}

.nav-button:hover {
  color: #195a7c !important;
}

.nav-button {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}
