/* Background handled in App.css */

.product-overview {
  padding-top: 8rem;
  padding-bottom: 5rem;
}

.product-overview h1, .product-overview h5 {
  letter-spacing: .7px;
}

.product-overview h5 {
  font-size: 1.10rem;
  margin-bottom: 1.5rem;
  color: #fff;
}

.product-overview h5, .product-overview-btn {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

.product-overview-btn {
  border-radius: 1.18rem;
  padding: .5rem 0;
  margin: 10px;
  width: 180px;
  border: none;
  -webkit-text-shadow: 0 0 5px rgba(0, 0, 0, .2);
  text-shadow: 0 0 5px rgba(0, 0, 0, .2);
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0 4px 7px 0 rgba(0, 0, 0, .2);
	font-size: .9rem;
  display: inline-block;
}

.product-overview-btn, .plugin-btn {
  transition: all .3s ease;
}

.product-overview-btn, .plugin-btn span {
  letter-spacing: .5px;
}

.product-overview-btn:hover {
  text-decoration: none !important;
  transform: scale(1.03) !important;
}

.product-overview p {
  color: #707070;
  letter-spacing: .3px;
}

.product-overview h1, .plugin-btn {
  color: #efa900;
}

.product-overview h1, .product-overview h2 {
  font-family: 'Montserrat', sans-serif;
}

.line {
  background-color: #000;
  height: 4px;
  margin-bottom: 1rem;
}

.product-overview h2 {
  color: #000;
}

.plugin-btn span {
  font-weight: 600;
}

.plugin-btn:hover {
  color: #195a7c;
}

.screenshot img {
  box-shadow: 0px 0px 35px 4px rgba(0,0,0,0.31);
}

.plugin-btn i {
  font-size: .9rem;
}
