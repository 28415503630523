.features {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.features i {
	font-size: 3rem;
}

.features h5 {
	margin-top: .5rem;
	font-family: 'Montserrat', sans-serif;
}

.features p {
	margin-bottom: 2rem;
	letter-spacing: .3px;
	font-weight: 300;
}

.features i, .features h5, .features p {
	color: #707070;
}

.features-btn {
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	letter-spacing: .5px;
	transition: all .3s ease;
  border: none;
  display: inline-block;
	margin-top: 10px;
}

.features-btn, .features-btn .fas, .features-btn:hover {
	color: #efa900;
}

.features-btn:hover {
	transform: scale(1.03) !important;
}

.features-btn, .features-btn .fas {
	font-size: 1rem;
}
