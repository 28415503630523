.w9form {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.w9form p {
  font-weight: 300;
  letter-spacing: .3px;
  color: #707070;
  margin-bottom: 1.5rem;
}

.w9-btn {
  transition: all .3s ease;
  background-color: #efa900;
  padding: .5rem 1rem;
  font-size: 1rem;
  border-radius: 5px;
  letter-spacing: .3px;
  border: none;
  box-shadow: 0 1px 10px 5px rgb(0, 0, 0, .2);
  -webkit-box-shadow: 0 1px 10px 5px rgb(0, 0, 0, .2);
  font-weight: 600;
}

.w9-btn, .w9-btn:hover {
  color: #195a7c;
}

.w9-btn:hover {
  opacity: .8;
}
