.main {
  padding: 5vh 0;
  /* background handled inside App.css @media query */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.header-section h1 {
	/* 'font-size' handled inside App.css @media query */
	letter-spacing: .8px;
	-webkit-text-shadow: 2px 2px 2px rgba(0, 0, 0, .8);
	text-shadow: 2px 2px 2px rgba(0, 0, 0, .8);
	font-family: 'Montserrat', sans-serif;
}

.header-section h1, .header-section p  {
	color: #fff;
}

.header-section p {
	/* 'font-size' handled inside App.css @media */
	-webkit-text-shadow: 0px 0px 3px rgba(0, 0, 0, .7);
	text-shadow: 0px 0px 3px rgba(0, 0, 0, .7);
	font-weight: 500;
}

.header-section p, .header-btn {
	letter-spacing: .5px;
}

.header-btn {
	border-radius: 1.18rem;
  padding: .5rem 0;
  margin: 10px;
  width: 140px;
  border: none;
  -webkit-text-shadow: 0 0 5px rgba(0, 0, 0, .2);
  text-shadow: 0 0 5px rgba(0, 0, 0, .2);
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0 4px 7px 0 rgba(0, 0, 0, .2);
	font-size: .9rem;
	transition: all .3s ease;
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
  display: inline-block;
}

.header-btn:hover {
  transform: scale(1.07) !important;
}
