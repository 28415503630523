.news {
	padding-top: 6rem;
	padding-bottom: 4rem;
}

.news-section {
	height: 400px;
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	margin-bottom: 1.5rem;
}

.news h4 {
	font-size: 1.4rem;
	letter-spacing: .5px;
	color: #000;
}

.news h4, .news-section h2 {
	font-family: 'Montserrat', sans-serif;
}

.news-section h2 {
	text-shadow: 2px 2px 6px #000;
	padding: 0px 10px 0px 10px;
	text-align: center;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff !important;
	font-size: 2.6rem;
}

.news-section-text p {
	color: #707070;
	font-weight: 300;
	letter-spacing: .3px;
}

.news-section-text p a {
	color: #efa900;
	transition: all .3s ease;
}

.news-section-text p a:hover {
	color: #195a7c;
}
