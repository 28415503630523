.privacy {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.privacy h5 {
	margin-bottom: 1rem;
	font-family: 'Montserrat', sans-serif;
	letter-spacing: .5px;
}

.privacy p {
	color: #707070;
	font-weight: 300;
	letter-spacing: .6px;
}

.privacy p a {
	color: #efa900;
	transition: all .3s ease;
}

.privacy p a:hover {
	color: #195a7c;
}
